<div class="div-width4">
  <img src="/assets/images/me.png" width=128 alt="...">&nbsp;
  <img class="img-shadow" src="/assets/images/me.png" width=128 alt="...">&nbsp;
  <img class="img-rounded" src="/assets/images/me.png" width=128 alt="...">&nbsp;
  <img class="img-shadow img-rounded" src="/assets/images/me.png" width=128 alt="...">
  <br><br>
  <p class="quote">&bdquo;Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
    invidunt ut
    labore et dolore
    magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
    gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
    elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
    eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
    dolor sit amet.&ldquo;<br /><br />
    - Christian
  </p>
  <p class="dark-color">Sample Text</p>
  <p class="light-color">Sample Text</p>
  <p class="primary-color">Sample Text</p>
  <p class="info-color">Sample Text</p>
  <p class="success-color">Sample Text</p>
  <p class="warning-color">Sample Text</p>
  <p class="danger-color">Sample Text</p>
  <h1 class="display-1">Display 1</h1>
  <h1 class="display-2">Display 2</h1>
  <h1 class="display-3">Display 3</h1>
  <h1 class="display-4">Display 4</h1>
  <h1>h1. Bootstrap heading</h1>
  <h2>h2. Bootstrap heading</h2>
  <h3>h3. Bootstrap heading</h3>
  <h4>h4. Bootstrap heading</h4>
  <h5>h5. Bootstrap heading</h5>
  <h6>h6. Bootstrap heading</h6>
  <br>
  <ngb-datepicker #d></ngb-datepicker><br><br>
  <button type="button" class="btn btn-info" popover="I will hide on blur" triggers="mouseenter:mouseleave">
    Hover over me!
  </button>
  <br><br>
  <button type="button" class="btn btn-primary" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
    popoverTitle="Popover on top" placement="top">
    Popover on top
  </button>&nbsp;
  <button type="button" class="btn btn-success" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
    popoverTitle="Popover on right" placement="right">
    Popover on right
  </button>&nbsp;
  <button type="button" class="btn btn-info" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
    popoverTitle="Popover auto" placement="auto">
    Popover auto
  </button>&nbsp;
  <button type="button" class="btn btn-dark" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
    popoverTitle="Popover on left" placement="left">
    Popover on left
  </button>&nbsp;
  <button type="button" class="btn btn-light" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
    popoverTitle="Popover on bottom" placement="bottom">
    Popover on bottom
  </button>
  <br><br>
  <nav aria-label="Page navigation example">
    <ul class="pagination pagination-sm">
      <li class="page-item">
        <a class="page-link" href="#" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item"><a class="page-link page-item-number" href="#">1</a></li>
      <li class="page-item"><a class="page-link page-item-number" href="#">2</a></li>
      <li class="page-item"><a class="page-link page-item-number active" href="#">3</a></li>
      <li class="page-item"><a class="page-link page-item-number" href="#">4</a></li>
      <li class="page-item"><a class="page-link page-item-number" href="#">5</a></li>
      <li class="page-item">
        <a class="page-link" href="#" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
  <br>
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item">
        <a class="page-link" href="#" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item"><a class="page-link page-item-number" href="#">1</a></li>
      <li class="page-item"><a class="page-link page-item-number" href="#">2</a></li>
      <li class="page-item"><a class="page-link page-item-number active" href="#">3</a></li>
      <li class="page-item"><a class="page-link page-item-number" href="#">4</a></li>
      <li class="page-item"><a class="page-link page-item-number" href="#">5</a></li>
      <li class="page-item">
        <a class="page-link" href="#" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
  <br>
  <button class="btn btn-primary" type="button" disabled>
    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    Loading...
  </button>&nbsp;
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-border text-success" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-border text-danger" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-border text-warning" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-border text-info" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-border text-light" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-border text-dark" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <br><br>
  <div class="div-width4">
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs" data-tabs="tabs">
          <li class="nav-item">
            <a class="nav-link active" href="#home" data-toggle="tab">First</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#updates" data-toggle="tab">Second</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#history" data-toggle="tab">Third</a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content text-center">
          <div class="tab-pane active" id="home">
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
            </p>
          </div>
          <div class="tab-pane" id="updates">
            <p>Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
              aliquyam
              erat, sed diam voluptua.</p>
          </div>
          <div class="tab-pane" id="history">
            <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
              no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab"
        aria-controls="nav-home" aria-selected="true">Home</a>
      <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab"
        aria-controls="nav-profile" aria-selected="false">Profile</a>
      <a class="nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab"
        aria-controls="nav-contact" aria-selected="false">Contact</a>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">Et et
      consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat
      veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing
      aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi
      duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi
      culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.</div>
    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">Nulla est ullamco ut
      irure incididunt nulla Lorem Lorem minim irure officia enim reprehenderit. Magna duis labore cillum sint
      adipisicing exercitation ipsum. Nostrud ut anim non exercitation velit laboris fugiat cupidatat. Commodo esse
      dolore fugiat sint velit ullamco magna consequat voluptate minim amet aliquip ipsum aute laboris nisi. Labore
      labore veniam irure irure ipsum pariatur mollit magna in cupidatat dolore magna irure esse tempor ad mollit.
      Dolore commodo nulla minim amet ipsum officia consectetur amet ullamco voluptate nisi commodo ea sit eu.</div>
    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">Sint sit mollit irure
      quis est nostrud cillum consequat Lorem esse do quis dolor esse fugiat sunt do. Eu ex commodo veniam Lorem aliquip
      laborum occaecat qui Lorem esse mollit dolore anim cupidatat. Deserunt officia id Lorem nostrud aute id commodo
      elit eiusmod enim irure amet eiusmod qui reprehenderit nostrud tempor. Fugiat ipsum excepteur in aliqua non et
      quis aliquip ad irure in labore cillum elit enim. Consequat aliquip incididunt ipsum et minim laborum laborum
      laborum et cillum labore. Deserunt adipisicing cillum id nulla minim nostrud labore eiusmod et amet. Laboris
      consequat consequat commodo non ut non aliquip reprehenderit nulla anim occaecat. Sunt sit ullamco reprehenderit
      irure ea ullamco Lorem aute nostrud magna.</div>
  </div>
  <br>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <a class="nav-link active" href="#">Active</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">Link</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">Link</a>
    </li>
    <li class="nav-item">
      <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
    </li>
  </ul>
  <br>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link active" href="#">Active</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">Link</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">Link</a>
    </li>
    <li class="nav-item">
      <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
    </li>
  </ul>
  <br>
  <div class="modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Modal body text goes here.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-left">Save changes</button>
          <button type="button" class="btn btn-dark" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="media">
    <img src="/assets/images/MyPartyLogo.png" width=64 class="mr-3" alt="...">
    <div class="media-body">
      <h5 class="mt-0">Media heading</h5>
      Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio,
      vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec
      lacinia congue felis in faucibus.
    </div>
  </div>
  <br>
  <div class="jumbotron">
    <h1 class="display-4">Hello, world!</h1>
    <p class="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to
      featured content or information.</p>
    <hr class="my-4">
    <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
    <a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a>
  </div>
  <br>
  <form>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Email</label>
        <input type="email" class="form-control" id="inputEmail4">
      </div>
      <div class="form-group col-md-6">
        <label for="inputPassword4">Password</label>
        <input type="password" class="form-control" id="inputPassword4">
      </div>
    </div>
    <div class="form-group">
      <label for="inputAddress">Address</label>
      <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St">
    </div>
    <div class="form-group">
      <label for="inputAddress2">Address 2</label>
      <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor">
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputCity">City</label>
        <input type="text" class="form-control" id="inputCity">
      </div>
      <div class="form-group col-md-4">
        <label for="inputState">State</label>
        <select id="inputState" class="form-control">
          <option class="single-option" selected>Choose...</option>
          <option class="single-option">...</option>
        </select>
      </div>
      <div class="form-group col-md-2">
        <label for="inputZip">Zip</label>
        <input type="text" class="form-control" id="inputZip">
      </div>
    </div>
    <div class="form-group">
      <div class="form-check">
        <label class="checkbox-container">Check me out
          <input type="checkbox" checked="checked">
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <button type="submit" class="btn btn-primary">Sign in</button>
  </form>
  <br><br>
  <div class="card-columns">
    <div class="card">
      <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="card-img-top" alt="...">
      <div class="card-body">
        <h5 class="card-title">Card title that wraps to a new line</h5>
        <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional
          content. This content is a little bit longer.</p>
      </div>
    </div>
    <div class="card p-3">
      <blockquote class="blockquote mb-0 card-body">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
        <footer class="blockquote-footer">
          <small class="text-muted">
            Someone famous in <cite title="Source Title">Source Title</cite>
          </small>
        </footer>
      </blockquote>
    </div>
    <div class="card">
      <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="card-img-top" alt="...">
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
    </div>
    <div class="card bg-primary text-white text-center p-3">
      <blockquote class="blockquote mb-0">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.</p>
        <footer class="blockquote-footer text-white">
          <small>
            Someone famous in <cite title="Source Title">Source Title</cite>
          </small>
        </footer>
      </blockquote>
    </div>
    <div class="card text-center">
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">This card has a regular title and short paragraphy of text below it.</p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
    </div>
    <div class="card">
      <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="card-img-top" alt="...">
    </div>
    <div class="card p-3 text-right">
      <blockquote class="blockquote mb-0">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
        <footer class="blockquote-footer">
          <small class="text-muted">
            Someone famous in <cite title="Source Title">Source Title</cite>
          </small>
        </footer>
      </blockquote>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">This is another card with title and supporting text below. This card has some additional
          content to make it slightly taller overall.</p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
    </div>
  </div>
  <br>
  <div class="card-deck">
    <div class="card">
      <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="card-img-top" alt="...">
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional
          content. This content is a little bit longer.</p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
    </div>
    <div class="card">
      <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="card-img-top" alt="...">
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
    </div>
    <div class="card">
      <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="card-img-top" alt="...">
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.
          This card has even longer content than the first to show that equal height action.</p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
    </div>
  </div>
  <br>
  <div class="card mb-3" style="max-width: 540px;">
    <div class="row no-gutters">
      <div class="col-sm-4">
        <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="card-img" alt="...">
      </div>
      <div class="col-sm-8">
        <div class="card-body">
          <h5 class="card-title">Card title</h5>
          <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional
            content. This content is a little bit longer.</p>
          <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-sm-6">
      <div class="card text-center">
        <div class="card-header">
          Featured
        </div>
        <div class="card-body">
          <h5 class="card-title">Special title treatment</h5>
          <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
          <a href="#" class="btn btn-primary">Go somewhere</a>
        </div>
        <div class="card-footer text-muted">
          2 days ago
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header">
          Quote
        </div>
        <div class="card-body">
          <blockquote class="blockquote mb-0">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
            <footer class="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
          </blockquote>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="card" style="width: 18rem;">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">Cras justo odio</li>
      <li class="list-group-item">Dapibus ac facilisis in</li>
      <li class="list-group-item">Vestibulum at eros</li>
    </ul>
    <div class="card-footer">
      Card footer
    </div>
  </div>
  <br>
  <div class="card" style="width: 18rem;">
    <div class="card-header">
      Featured
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">Cras justo odio</li>
      <li class="list-group-item">Dapibus ac facilisis in</li>
      <li class="list-group-item">Vestibulum at eros</li>
    </ul>
  </div>
  <br>
  <div class="card" style="width: 18rem;">
    <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="card-img-top" alt="...">
    <div class="card-body">
      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
        content.</p>
    </div>
  </div>
  <br>
  <div class="card" style="width: 18rem;">
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
        content.</p>
      <a href="#" class="card-link">Card link</a>
      <a href="#" class="card-link">Another link</a>
    </div>
  </div>
  <br>
  <div class="card" style="width: 18rem;">
    <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="card-img-top" alt="...">
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
        content.</p>
      <a href="#" class="btn btn-primary">Go somewhere</a>
    </div>
  </div>
  <br>
  <p>
    <a class="btn btn-primary" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false"
      aria-controls="multiCollapseExample1">Toggle first element</a>
    <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#multiCollapseExample2"
      aria-expanded="false" aria-controls="multiCollapseExample2">Toggle second element</button>
    <button class="btn btn-primary" type="button" data-toggle="collapse" data-target=".multi-collapse"
      aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2">Toggle both elements</button>
  </p>
  <div class="row">
    <div class="col">
      <div class="collapse multi-collapse" id="multiCollapseExample1">
        <div class="card card-body">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
          keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
    <div class="col">
      <div class="collapse multi-collapse" id="multiCollapseExample2">
        <div class="card card-body">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
          keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
  </div>
  <br>
</div>
<div class="div-width4">
  <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="d-block w-100" alt="...">
        <div class="carousel-caption d-none d-md-block">
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </div>
      </div>
      <div class="carousel-item">
        <img src="/assets/images/kelsey-chance-tAH2cA_BL5g-unsplash.jpg" class="d-block w-100" alt="...">
        <div class="carousel-caption d-none d-md-block">
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>
      <div class="carousel-item">
        <img src="/assets/images/marvin-meyer-2SDjvx5jEZQ-unsplash.jpg" class="d-block w-100" alt="...">
        <div class="carousel-caption d-none d-md-block">
          <h5>Third slide label</h5>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
<br><br>
<div class="div-width4">
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="/assets/images/kelsey-chance-tAH2cA_BL5g-unsplash.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="/assets/images/marvin-meyer-2SDjvx5jEZQ-unsplash.jpg" class="d-block w-100" alt="...">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
<br>
<div class="div-width3">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">Home</li>
    </ol>
  </nav>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Library</li>
    </ol>
  </nav>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item"><a href="#">Library</a></li>
      <li class="breadcrumb-item active" aria-current="page">Data</li>
    </ol>
  </nav>
  <br>
  <span class="badge badge-pill badge-primary">Primary</span>&nbsp;
  <span class="badge badge-pill badge-secondary">Secondary</span>&nbsp;
  <span class="badge badge-pill badge-success">Success</span>&nbsp;
  <span class="badge badge-pill badge-danger">Danger</span>&nbsp;
  <span class="badge badge-pill badge-warning">Warning</span>&nbsp;
  <span class="badge badge-pill badge-info">Info</span>&nbsp;
  <span class="badge badge-pill badge-light">Light</span>&nbsp;
  <span class="badge badge-pill badge-dark">Dark</span>
  <br><br>
  <span class="badge badge-primary">Primary</span>&nbsp;
  <span class="badge badge-secondary">Secondary</span>&nbsp;
  <span class="badge badge-success">Success</span>&nbsp;
  <span class="badge badge-danger">Danger</span>&nbsp;
  <span class="badge badge-warning">Warning</span>&nbsp;
  <span class="badge badge-info">Info</span>&nbsp;
  <span class="badge badge-light">Light</span>&nbsp;
  <span class="badge badge-dark">Dark</span>
  <br><br>
  <button type="button" class="btn btn-primary">
    Notifications <span class="badge badge-dark">4</span>
  </button>
  <br>
  <br>
  <div class="alert alert-primary" role="alert">
    This is a primary alert!
  </div>
  <div class="alert alert-info" role="alert">
    This is a info alert!
  </div>
  <div class="alert alert-success" role="alert">
    This is a success alert!
  </div>
  <div class="alert alert-danger" role="alert">
    This is a danger alert!
  </div>
  <div class="alert alert-warning" role="alert">
    This is a warning alert!
  </div>
  <br>
  <span class="progress-badge">Primary</span>
  <span class="progress-value">25%</span>
  <div class="progress">
    <div class="progress-bar progress-primary" role="progressbar" style="width: 25%" aria-valuenow="25"
      aria-valuemin="0" aria-valuemax="100"></div>
  </div>
  <br>
  <span class="progress-badge">Info</span>
  <span class="progress-value">25%</span>
  <div class="progress">
    <div class="progress-bar progress-info" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0"
      aria-valuemax="100"></div>
  </div>
  <br>
  <span class="progress-badge">Success</span>
  <span class="progress-value">50%</span>
  <div class="progress">
    <div class="progress-bar progress-success" role="progressbar" style="width: 50%" aria-valuenow="50"
      aria-valuemin="0" aria-valuemax="100"></div>
  </div>
  <br>
  <span class="progress-badge">Warning</span>
  <span class="progress-value">50%</span>
  <div class="progress">
    <div class="progress-bar progress-warning" role="progressbar" style="width: 50%" aria-valuenow="50"
      aria-valuemin="0" aria-valuemax="100"></div>
  </div>
  <br>
  <span class="progress-badge">Danger</span>
  <span class="progress-value">75%</span>
  <div class="progress">
    <div class="progress-bar progress-danger" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0"
      aria-valuemax="100"></div>
  </div>
  <br>
  <span class="progress-badge">Light</span>
  <span class="progress-value">75%</span>
  <div class="progress">
    <div class="progress-bar progress-light" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0"
      aria-valuemax="100"></div>
  </div>
</div>
<br>
<p class="text-muted">You can use the mark tag to <mark>highlight</mark> text.</p>
<p class="text-muted"><del>This line of text is meant to be treated as deleted text.</del></p>
<p class="text-muted"><s>This line of text is meant to be treated as no longer accurate.</s></p>
<p class="text-muted"><ins>This line of text is meant to be treated as an addition to the document.</ins></p>
<p class="text-muted"><u>This line of text will render as underlined</u></p>
<p class="text-muted"><small>This line of text is meant to be treated as fine print.</small></p>
<p class="text-muted"><strong>This line rendered as bold text.</strong></p>
<p class="text-muted"><em>This line rendered as italicized text.</em></p>
<br>
<fieldset disabled>
  <div class="form-group">
    <label for="disabledTextInput">Disabled input</label>
    <input type="text" id="disabledTextInput" class="form-control" placeholder="Disabled input">
  </div>
</fieldset>
<br>
<div class="form-group">
  <label for="exampleFormControlSelect1">Example select</label>
  <select class="form-control" id="exampleFormControlSelect1">
    <option class="single-option">1</option>
    <option class="single-option">2</option>
    <option class="single-option">3</option>
    <option class="single-option">4</option>
    <option class="single-option">5</option>
  </select>
</div>
<div class="form-group">
  <label for="exampleFormControlSelect2">Example multiple select</label>
  <select multiple class="form-control" id="exampleFormControlSelect2">
    <option>1</option>
    <option>2</option>
    <option>3</option>
    <option>4</option>
    <option>5</option>
  </select>
</div>
<div class="form-group">
  <label for="exampleFormControlTextarea1">Example textarea</label>
  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
</div>
<br>
<nav class="navbar navbar-expand-sm navbar-dark bg-primary">
  <a class="navbar-brand" href="#"><b>MyPurge</b></a>
  <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
    data-toggle="collapse" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarTogglerDemo01" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a class="nav-link light-link" href="#">Home<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link light-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link light-link disabled" href="#">Disabled</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link light-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control search-bar" type="search" placeholder="Search">
    </form>
  </div>
</nav><br>
<nav class="navbar navbar-expand-sm navbar-dark bg-dark">
  <a class="navbar-brand" href="#">
    <img src='/assets/images/MyPartyLogo.png' height="31" alt="">
  </a>
  <button class="navbar-toggler" type="button" (click)="isCollapsed2 = !isCollapsed2"
    [attr.aria-expanded]="!isCollapsed2" data-toggle="collapse" aria-controls="navbarTogglerDemo02"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarTogglerDemo02" [ngbCollapse]="isCollapsed2">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a class="nav-link light-link" href="#"><i class="fas fa-home"></i> Home<span
            class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link light-link" href="#"><i class="fas fa-user-circle"></i> Profile</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link light-link" href="#"><i class="fas fa-cog"></i> Settings</a>
      </li>
    </ul>
    <ul class="navbar-nav mt-2 mt-lg-0 ml-auto justify-content-end">
      <li class="nav-item active">
        <a class="nav-link light-link" href="#"><i class="fab fa-instagram"></i><span
            class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link light-link" href="#"><i class="fab fa-facebook"></i><span
            class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link light-link" href="#"><i class="fab fa-twitter"></i><span class="sr-only">(current)</span></a>
      </li>
    </ul>
  </div>
</nav><br>
<nav class="navbar navbar-expand-sm navbar-dark bg-dark">
  <a class="navbar-brand" href="#">
    <img src='/assets/images/MyPartyLogo.png' height="31" alt="">
    MyPurge
  </a>
  <button class="navbar-toggler" type="button" (click)="isCollapsed3 = !isCollapsed3"
    [attr.aria-expanded]="!isCollapsed3" data-toggle="collapse" aria-controls="navbarTogglerDemo03"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarTogglerDemo02" [ngbCollapse]="isCollapsed3">
    <ul class="navbar-nav mt-2 mt-lg-0 ml-auto w-100 justify-content-end">
      <li class="nav-item active">
        <a class="nav-link light-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link light-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link light-link disabled" href="#">Disabled</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link light-link dropdown-toggle" href="#" id="navbarDropdown2" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown3">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control search-bar" type="search" placeholder="Search">
    </form>
  </div>
</nav>

<br><br>
<div class="slider-container">
  <nouislider [connect]=[false,true,false] [step]=1 [min]="0" [max]="1000" [(ngModel)]="slider2"></nouislider>
</div>
Range: {{slider2}}
<br>
<div class="slider-container">
  <nouislider [connect]=[true,false] [step]=1 [min]="0" [max]="1000" [(ngModel)]="slider1"></nouislider>
</div>
Value: {{slider1}}
<br>
<br>
<label class="radio-container">Radio1
  <input type="radio" checked="checked" name="radio-group-1">
  <span class="radio-checkmark"></span>
</label>
<label class="radio-container">Radio2
  <input type="radio" name="radio-group-1">
  <span class="radio-checkmark"></span>
</label>
<label class="radio-container radio-disabled">Disabled Radio1
  <input disabled type="radio" checked="checked" name="radio-group-2">
  <span class="radio-checkmark"></span>
</label>
<label class="radio-container radio-disabled">Disabled Radio2
  <input disabled type="radio" name="radio-group-2">
  <span class="radio-checkmark"></span>
</label>
<br>
<label class="checkbox-container">Checked
  <input type="checkbox" checked="checked">
  <span class="checkmark"></span>
</label>
<label class="checkbox-container">Unchecked
  <input type="checkbox">
  <span class="checkmark"></span>
</label>
<label class="checkbox-container checkbox-disabled">Disabled Checked
  <input disabled type="checkbox" checked="checked">
  <span class="checkmark"></span>
</label>
<label class="checkbox-container checkbox-disabled">Disabled Unchecked
  <input disabled type="checkbox">
  <span class="checkmark"></span>
</label>
<br>
<div class="div-width2 input-group">
  <span class="input-group-addon"><i class="far fa-envelope"></i></span>
  <input id="email" type="text" class="form-control input-padding" name="email" placeholder="Email">
</div><br>
<div class="div-width2 input-group">
  <span class="input-group-addon"><i class="fas fa-lock"></i></span>
  <input id="password" type="password" class="form-control input-padding" name="password" placeholder="Password">
</div><br>
<div class="div-width2 input-group">
  <span class="input-group-addon"></span>
  <input id="standard" type="text" class="form-control no-icon-padding" name="standard" placeholder="Standard">
</div><br>
<div class="div-width2 input-group">
  <input id="input-success" type="text" class="form-control no-icon-padding" name="success" value="Success">
  <span class="input-group-addon input-icon-right"><i class="fas fa-check"></i></span>
</div><br>
<div class="div-width2 input-group">
  <span class="input-group-addon input-icon-right"><i class="fas fa-times"></i></span>
  <input id="input-forbidden" type="text" class="form-control no-icon-padding" name="forbidden" value="Forbidden">
</div>
<br>
<br>
<button type="button" class="btn btn-no-circle">
  <span class="far fa-thumbs-up"></span>
</button>
<button type="button" class="btn btn-no-circle">
  <span class="far fa-comment"></span>
</button>
<br>
<button type="button" class="btn btn-primary btn-circle">
  <span class="far fa-thumbs-up center-icon"></span>
</button>
<button type="button" class="btn btn-primary btn-circle">
  <span class="far fa-comment center-icon"></span>
</button>
<br><br>
<ui-switch size="small"></ui-switch>
<br>
<div class="div-width1">
  <p><button type="button" class="btn btn-dark btn-block">Dark</button></p>
  <p><button type="button" class="btn btn-light btn-block">Light</button></p>
  <p><button type="button" class="btn btn-primary btn-block">Primary</button></p>
  <p><button type="button" class="btn btn-primary btn-block btn-rounded">Primary</button></p>
  <p><button type="button" class="btn btn-secondary btn-block">Secondary</button></p>
  <p><button type="button" class="btn btn-info btn-block">Info</button></p>
  <p><button type="button" class="btn btn-success btn-block">Success</button></p>
  <p><button type="button" class="btn btn-warning btn-block">Warning</button></p>
  <p><button type="button" class="btn btn-danger btn-block">Danger</button></p>
  <p><button type="button" class="btn btn-link btn-block">Weiter</button></p>
  <p><button type="button" class="btn btn-outline-warning">Warning</button></p>
  <button type="button" class="btn btn-primary" disabled>Disabled</button><br>
</div>
<br>
<div class="div-width2">
  <button type="button" class="btn btn-primary btn-lg">Large button</button>
</div>
<br>
<div class="div-width1">
  <button type="button" class="btn btn-primary btn-sm">Small button</button>
</div>
