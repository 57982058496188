<div class="bg-color">
  <app-header></app-header>
  <div class="page-header">
    <div class="page-header-image" appParallax [ratio]="-0.2"></div>
    <div class="container">
      <div class="align-mid frame">
        <img class="img-rounded img-shadow" id="logoOnBackground"
          src="/assets/images/profilePictures/businessProfileSample.png">
        <h3><b>Nathan Gray</b></h3>
        <p class="dev-color">ARCHITECT</p>
        <div class="margin-left">
          <button class="btn btn-margin btn-primary btn-rounded">Follow</button>
          <button type="button" class="btn btn-no-circle">
            <span class="fab fa-twitter"></span>
          </button>
          <button type="button" class="btn btn-no-circle">
            <span class="fab fa-linkedin-in"></span>
          </button>
        </div>
        <div class="margin-right d-flex justify-content-center">
          <div class="social-margin">
            <h1>53</h1>
            <p>Likes</p>
          </div>
          <div class="social-margin">
            <h1>26</h1>
            <p>Comments</p>
          </div>
          <div class="social-margin">
            <h1>21</h1>
            <p>Redirects</p>
          </div>
        </div>
        <div class="container margin-quote">
          <p class="quote">&bdquo;Enlightenment insofar war oneself selfish reason decieve ubermensch marvelous
            abstract.
            Evil truth christianity faithful endless zarathustra god evil noble will moral ubermensch. Depths evil
            grandeur society horror salvation society ideal evil disgust salvation. Holiest moral battle chaos ultimate
            ubermensch hatred battle.&ldquo;<br /><br />
            - Nietzsche Ipsum
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
