<app-header></app-header>
<div class="page-header">
  <div class="page-header-image" id="page-header-image" appParallax [ratio]="-0.3"></div>
  <div class="container">
    <div class="align-mid">
      <img id="logoOnBackground" src="/assets/images/easedLogoCircleOutline.svg">
      <h1 class="logo-text">E &nbsp;A &nbsp;S &nbsp;E &nbsp;D</h1>
      <h6>Bootstrap 4 UI kit for Angular</h6>
    </div>
  </div>
</div>
<div class="container-text" id="instructionText">
  <div class="container overall-container">
    <img id="responsiveDesignSample" src="/assets/images/responsiveDesignSamplePicsFilled2.png">
    <h2 class="h-margin-bottom primary-color"><b>Components</b></h2>
    <h3><b>Alerts</b></h3>
    <div class="container">
      <div class="alert alert-primary" role="alert">
        This is a primary alert!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="alert alert-info" role="alert">
        This is an info alert!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="alert alert-success" role="alert">
        This is a success alert!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="alert alert-danger" role="alert">
        This is a danger alert!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="alert alert-warning" role="alert">
        This is a warning alert!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div><br>
      <div class="alert alert-primary" role="alert">
        <i class="fas fa-medal"></i>
        &nbsp;This is an alert <b>with an icon</b>!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div><br><br>
    <h3><b>Badges</b></h3>
    <div class="container">
      <button type="button" class="btn btn-primary">
        Notifications <span class="badge badge-dark">4</span>
      </button><br><br>
      <h5>Contextual variations</h5>
      <span class="badge badge-primary">Primary</span>&nbsp;
      <span class="badge badge-secondary">Secondary</span>&nbsp;
      <span class="badge badge-success">Success</span>&nbsp;
      <span class="badge badge-danger">Danger</span>&nbsp;
      <span class="badge badge-warning">Warning</span>&nbsp;
      <span class="badge badge-info">Info</span>&nbsp;
      <span class="badge badge-light">Light</span>&nbsp;
      <span class="badge badge-dark">Dark</span>
      <br><br>
      <h5>Pill badges</h5>
      <span class="badge badge-pill badge-primary">Primary</span>&nbsp;
      <span class="badge badge-pill badge-secondary">Secondary</span>&nbsp;
      <span class="badge badge-pill badge-success">Success</span>&nbsp;
      <span class="badge badge-pill badge-danger">Danger</span>&nbsp;
      <span class="badge badge-pill badge-warning">Warning</span>&nbsp;
      <span class="badge badge-pill badge-info">Info</span>&nbsp;
      <span class="badge badge-pill badge-light">Light</span>&nbsp;
      <span class="badge badge-pill badge-dark">Dark</span>
    </div><br><br>
    <h3><b>Breadcrumb</b></h3>
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Home</li>
        </ol>
      </nav>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Library</li>
        </ol>
      </nav>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Library</a></li>
          <li class="breadcrumb-item active" aria-current="page">Data</li>
        </ol>
      </nav>
    </div><br><br>
    <h3><b>Buttons</b></h3>
    <div class="container">
      <h5>Standard</h5>
      <button type="button" class="btn btn-primary btn-width">Primary</button>&nbsp;
      <button type="button" class="btn btn-secondary btn-width">Secondary</button>&nbsp;
      <button type="button" class="btn btn-info btn-width">Info</button>&nbsp;
      <button type="button" class="btn btn-success btn-width">Success</button>&nbsp;
      <button type="button" class="btn btn-warning btn-width">Warning</button>&nbsp;
      <button type="button" class="btn btn-danger btn-width">Danger</button>&nbsp;
      <button type="button" class="btn btn-dark btn-width">Dark</button>&nbsp;
      <button type="button" class="btn btn-light btn-width">Light</button>&nbsp;
      <br><br>
      <h5>Outline</h5>
      <button type="button" class="btn btn-outline-primary btn-width">Primary</button>&nbsp;
      <button type="button" class="btn btn-outline-secondary btn-width">Secondary</button>&nbsp;
      <button type="button" class="btn btn-outline-info btn-width">Info</button>&nbsp;
      <button type="button" class="btn btn-outline-success btn-width">Success</button>&nbsp;
      <button type="button" class="btn btn-outline-warning btn-width">Warning</button>&nbsp;
      <button type="button" class="btn btn-outline-danger btn-width">Danger</button>&nbsp;
      <button type="button" class="btn btn-outline-dark btn-width">Dark</button>&nbsp;
      <button type="button" class="btn btn-outline-light btn-width">Light</button>&nbsp;
      <br><br>
      <h5>Icons</h5>
      <button type="button" class="btn btn-no-circle">
        <span class="far fa-thumbs-up"></span>
      </button>&nbsp;
      <button type="button" class="btn btn-no-circle">
        <span class="far fa-comment"></span>
      </button>
      <br>
      <button type="button" class="btn btn-primary btn-circle">
        <span class="far fa-thumbs-up center-icon"></span>
      </button>&nbsp;
      <button type="button" class="btn btn-primary btn-circle">
        <span class="far fa-comment center-icon"></span>
      </button>
      <br><br>
      <h5>Sizes</h5>
      <button type="button" class="btn btn-primary btn-sm">Small button</button>&nbsp;
      <button type="button" class="btn btn-primary">Medium button</button>&nbsp;
      <button type="button" class="btn btn-primary btn-lg">Large button</button>
      <br><br>
      <h5>Other Modifications</h5>
      <button type="button" class="btn btn-primary" disabled>Disabled</button>&nbsp;
      <button type="button" class="btn btn-outline-primary" disabled>Disabled</button>&nbsp;
      <button type="button" class="btn btn-primary btn-rounded">Rounded</button>&nbsp;
      <button type="button" class="btn btn-link">Link</button>
      <br><br><br>
    </div>
    <h3><b>Cards</b></h3>
    <div class="container">
      <div class="mx-auto">
        <div class="row">
          <div class="col-sm-12">
            <div class="card text-center">
              <div class="card-header">
                Featured
              </div>
              <div class="card-body">
                <h5 class="card-title">Special title treatment</h5>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a class="btn btn-primary">Go somewhere</a>
              </div>
              <div class="card-footer text-muted">
                2 days ago
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4 d-flex justify-content-center">
            <div class="card" style="width: 16rem;">
              <img src="/assets/images/alasdair-elmes-ULHxWq8reao-unsplash.jpg" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the
                  card's
                  content.</p>
                <a class="btn btn-primary">Go somewhere</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex justify-content-center">
            <div class="card" style="width: 16rem; height: 19rem;">
              <img src="/assets/images/kelsey-chance-tAH2cA_BL5g-unsplash.jpg" class="card-img-top" alt="...">
              <div class="card-body">
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the
                  card's
                  content.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex justify-content-center">
            <div class="card" style="width: 16rem; height: 15rem;">
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the
                  card's
                  content.</p>
                <a class="card-link" href="#">Card link</a>
                <a class="card-link" href="#">Another link</a>
              </div>
            </div>
          </div>
        </div>
        <br><br>
      </div>
    </div>
    <h3><b>Carousel</b></h3>
    <div class="container">
      <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner" ng-swipe-left="$parent.vm.next()" ng-swipe-right="$parent.vm.prev()" ng-transclude>
          <div class="carousel-item active">
            <img src="/assets/images/carousel/barber2.jpg" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block">
              <h5>First slide label</h5>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </div>
          </div>
          <div class="carousel-item">
            <img src="/assets/images/carousel/barber1.jpg" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block">
              <h5>Second slide label</h5>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
          <div class="carousel-item">
            <img src="/assets/images/carousel/barber3.jpg" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block">
              <h5>Third slide label</h5>
              <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div><br><br>
    <h3><b>Charts</b></h3>
    <div class="container" id="polar-chart-parent">
      <video (click)="playVid()" id="graphVid" width="100%">
        <source src="/assets/videos/easedChartWeb.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <p>Tap to watch a preview.</p>
    </div>
    <br><br>
    <h3><b>Datepicker</b></h3>
    <div class="container">
      <app-datepicker></app-datepicker>
    </div><br><br>
    <h3><b>Dropdowns</b></h3>
    <div class="container">
      Nav-Dropdown available, see Docs.<br>
      <img src="/assets/images/samples/dropdownExample.png" alt="...">
    </div>
    <br><br>
    <h3><b>Forms</b></h3>
    <div class="container">
      <h5>Inputs</h5><br>
      <div class="input-width-example input-group">
        <span class="input-group-addon"><i class="far fa-envelope"></i></span>
        <input id="email" type="text" class="form-control input-padding" name="email" placeholder="Email">
      </div><br>
      <div class="input-width-example input-group">
        <span class="input-group-addon"><i class="fas fa-lock"></i></span>
        <input id="password" type="password" class="form-control input-padding" name="password" placeholder="Password">
      </div><br>
      <div class="input-width-example input-group">
        <span class="input-group-addon"></span>
        <input id="standard" type="text" class="form-control no-icon-padding" name="standard" placeholder="Standard">
      </div><br>
      <div class="input-width-example input-group">
        <input id="input-success" type="text" class="form-control no-icon-padding" name="success" value="Success">
        <span class="input-group-addon input-icon-right"><i class="fas fa-check"></i></span>
      </div><br>
      <div class="input-width-example input-group">
        <span class="input-group-addon input-icon-right"><i class="fas fa-times"></i></span>
        <input id="input-unavailable" type="text" class="form-control no-icon-padding" name="unavailable"
          value="Unavailable">
      </div>
      <br><br>
      <h5>Form Groups</h5>
      <fieldset disabled>
        <div class="form-group">
          <label for="disabledTextInput">Disabled input</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="Disabled input">
        </div>
      </fieldset>
      <div class="form-group">
        <label for="exampleFormControlSelect1">Example select</label>
        <select class="form-control" id="exampleFormControlSelect1">
          <option class="single-option">1</option>
          <option class="single-option">2</option>
          <option class="single-option">3</option>
          <option class="single-option">4</option>
          <option class="single-option">5</option>
        </select>
      </div>
      <div class="form-group">
        <label for="exampleFormControlSelect2">Example multiple select</label>
        <select multiple class="form-control" id="exampleFormControlSelect2">
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </select>
      </div>
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Example textarea</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
      </div><br>
      <h5>Radio Buttons</h5>
      <label class="radio-container">Radio1
        <input type="radio" checked="checked" name="radio-group-1">
        <span class="radio-checkmark"></span>
      </label>
      <label class="radio-container">Radio2
        <input type="radio" name="radio-group-1">
        <span class="radio-checkmark"></span>
      </label>
      <label class="radio-container radio-disabled">Disabled Radio1
        <input disabled type="radio" checked="checked" name="radio-group-2">
        <span class="radio-checkmark"></span>
      </label>
      <label class="radio-container radio-disabled">Disabled Radio2
        <input disabled type="radio" name="radio-group-2">
        <span class="radio-checkmark"></span>
      </label><br>
      <h5>Checkboxes</h5>
      <label class="checkbox-container">Checked
        <input type="checkbox" checked="checked">
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">Unchecked
        <input type="checkbox">
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container checkbox-disabled">Disabled Checked
        <input disabled type="checkbox" checked="checked">
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container checkbox-disabled">Disabled Unchecked
        <input disabled type="checkbox">
        <span class="checkmark"></span>
      </label>
    </div><br><br>
    <h3><b>Icons (Samples)</b></h3>
    <div class="container">
      <div class="center icon-block-width">
        <div class="hover-icons" (mouseover)='showIconText()' (mouseout)='hideIconText()'>
          <button type="button" class="btn btn-light btn-circle btn-margin not-right-button left-button">
            <span class="fab fa-bitcoin center-icon"></span>
          </button>
          <button type="button" class="btn btn-light btn-circle btn-margin not-right-button">
            <span class="fas fa-code center-icon"></span>
          </button>
          <button type="button" class="btn btn-light btn-circle btn-margin not-right-button">
            <span class="fas fa-comments center-icon"></span>
          </button>
          <button type="button" class="btn btn-light btn-circle btn-margin">
            <span class="fas fa-landmark center-icon"></span>
          </button><br>
          <button type="button" class="btn btn-light btn-circle btn-margin not-right-button left-button">
            <span class="fas fa-graduation-cap center-icon"></span>
          </button>
          <button type="button" class="btn btn-light btn-circle btn-margin not-right-button">
            <span class="fas fa-music center-icon"></span>
          </button>
          <button type="button" class="btn btn-light btn-circle btn-margin not-right-button">
            <span class="fas fa-paper-plane center-icon"></span>
          </button>
          <button type="button" class="btn btn-light btn-circle btn-margin">
            <span class="fab fa-twitter center-icon"></span>
          </button><br>
          <button type="button" class="btn btn-light btn-circle btn-margin not-right-button left-button">
            <span class="fas fa-wifi center-icon"></span>
          </button>
          <button type="button" class="btn btn-light btn-circle btn-margin not-right-button">
            <span class="fas fa-volume-mute center-icon"></span>
          </button>
          <button type="button" class="btn btn-light btn-circle btn-margin not-right-button">
            <span class="fas fa-user-secret center-icon"></span>
          </button>
          <button type="button" class="btn btn-light btn-circle btn-margin">
            <span class="fas fa-adjust center-icon"></span>
          </button>
        </div>
      </div>
      <div class="center">
        <a href="https://fontawesome.com/icons?d=gallery" target="_blank">
          <button type="button" class="btn btn-light btn-circle">
            <span class="fab fa-font-awesome-flag center-icon"></span>
          </button>
        </a>
      </div>
      <div class="align-mid">
        <p class="no-opacity opacity-margin primary-color"><b>Click the flag to see all icons!</b></p>
      </div>
    </div>
    <h3><b>iFrames</b></h3>
    <div class="container center">
      <iframe src="https://open.spotify.com/embed/playlist/3ltm2rDGkXnqpsEYj6qpjm" width="1000" height="420"
        frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    </div><br><br>
    <h3><b>Images</b></h3>
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-6">
          <p class="text-muted"><b>Square</b></p>
          <img class="profile-image" src="/assets/images/profilePictures/profile1_128.png" width=128 alt="...">&nbsp;
        </div>
        <div class="col-md-3 col-6">
          <p class="text-muted"><b>Square Raised</b></p>
          <img class="profile-image img-shadow" src="/assets/images/profilePictures/profile3_128.png" width=128
            alt="...">&nbsp;
        </div>
        <div class="col-md-3 col-6">
          <p class="text-muted"><b>Circle</b></p>
          <img class="profile-image img-rounded" src="/assets/images/profilePictures/profile2_128.png" width=128
            alt="...">&nbsp;
        </div>
        <div class="col-md-3 col-6">
          <p class="text-muted"><b>Circle Raised</b></p>
          <img class="profile-image img-shadow img-rounded" src="/assets/images/profilePictures/profile4_128.jpg"
            width=128 alt="...">
        </div>
      </div>
    </div>
    <h3><b>Jumbotron</b></h3>
    <div class="container">
      <div class="jumbotron">
        <h1 class="display-4">Hello, world!</h1>
        <p class="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to
          featured content or information.</p>
        <hr class="my-4">
        <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
        <a class="btn btn-primary btn-lg" role="button">Learn more</a>
      </div>
    </div><br><br>
    <h3><b>Media Object</b></h3>
    <div class="container">
      <div class="media">
        <img src="/assets/images/easedLogoCircleOutline.svg" width=64 class="mr-3" alt="...">
        <div class="media-body">
          <h5 class="mt-0">Media heading</h5>
          Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio,
          vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec
          lacinia congue felis in faucibus.
        </div>
      </div>
    </div><br><br>
    <h3><b>Modal</b></h3>
    <div class="container">
      <!--
      <h5>Static example</h5><br>
      <div class="modal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Modal title</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Modal body text goes here.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary btn-left">Save changes</button>
              <button type="button" class="btn btn-dark" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div><br><br>-->
      <app-modal></app-modal>
    </div><br>
    <h3><b>Navs</b></h3>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs" data-tabs="tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#home" data-toggle="tab">First</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#updates" data-toggle="tab">Second</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#history" data-toggle="tab">Third</a>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content text-center">
            <div class="tab-pane active" id="home">
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore
                et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
                rebum.
              </p>
            </div>
            <div class="tab-pane" id="updates">
              <p>Lorem ipsum dolor sit
                amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam
                erat, sed diam voluptua.</p>
            </div>
            <div class="tab-pane" id="history">
              <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
            </div>
          </div>
        </div>
      </div>
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab"
            aria-controls="nav-home" aria-selected="true">Home</a>
          <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab"
            aria-controls="nav-profile" aria-selected="false">Profile</a>
          <a class="nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab"
            aria-controls="nav-contact" aria-selected="false">Contact</a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">Et et
          consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam.
          Fugiat
          veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing
          aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do
          nisi
          duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa
          nisi
          culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
        </div>
        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">Nulla est
          ullamco
          ut
          irure incididunt nulla Lorem Lorem minim irure officia enim reprehenderit. Magna duis labore cillum sint
          adipisicing exercitation ipsum. Nostrud ut anim non exercitation velit laboris fugiat cupidatat. Commodo
          esse
          dolore fugiat sint velit ullamco magna consequat voluptate minim amet aliquip ipsum aute laboris nisi.
          Labore
          labore veniam irure irure ipsum pariatur mollit magna in cupidatat dolore magna irure esse tempor ad mollit.
          Dolore commodo nulla minim amet ipsum officia consectetur amet ullamco voluptate nisi commodo ea sit eu.
        </div>
        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">Sint sit mollit
          irure
          quis est nostrud cillum consequat Lorem esse do quis dolor esse fugiat sunt do. Eu ex commodo veniam Lorem
          aliquip
          laborum occaecat qui Lorem esse mollit dolore anim cupidatat. Deserunt officia id Lorem nostrud aute id
          commodo
          elit eiusmod enim irure amet eiusmod qui reprehenderit nostrud tempor. Fugiat ipsum excepteur in aliqua non
          et
          quis aliquip ad irure in labore cillum elit enim. Consequat aliquip incididunt ipsum et minim laborum
          laborum
          laborum et cillum labore. Deserunt adipisicing cillum id nulla minim nostrud labore eiusmod et amet. Laboris
          consequat consequat commodo non ut non aliquip reprehenderit nulla anim occaecat. Sunt sit ullamco
          reprehenderit
          irure ea ullamco Lorem aute nostrud magna.</div>
      </div>
      <br>
      <ul class="nav nav-pills">
        <li class="nav-item">
          <a class="nav-link active">Active</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" tabindex="-1" aria-disabled="true">Disabled</a>
        </li>
      </ul>
      <br>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active">Active</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" tabindex="-1" aria-disabled="true">Disabled</a>
        </li>
      </ul>
    </div><br><br>
    <h3><b>Navbar</b></h3>
    <div class="container">
      <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
        <a class="navbar-brand">
          <img src='/assets/images/easedLogoCircleOutline.svg' height="31" alt="">
        </a>
        <button class="navbar-toggler" type="button" (click)="isCollapsed2 = !isCollapsed2"
          [attr.aria-expanded]="!isCollapsed2" data-toggle="collapse" aria-controls="navbarTogglerDemo02"
          aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo02" [ngbCollapse]="isCollapsed2">
          <i class="fas fa-times fa-lg" (click)="isCollapsed2 = !isCollapsed2"></i>
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item active">
              <a class="nav-link light-link"><i class="fas fa-home"></i> Home<span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item active">
              <a class="nav-link light-link"><i class="fas fa-user-circle"></i> Profile</a>
            </li>
            <li class="nav-item active">
              <a class="nav-link light-link"><i class="fas fa-cog"></i> Settings</a>
            </li>
          </ul>
          <form class="form-inline my-2 my-lg-0">
            <input class="form-control search-bar" type="search" placeholder="Search">
          </form>
        </div>
      </nav>
    </div><br><br>
    <h3><b>Pagination</b></h3>
    <div class="container">
      <nav aria-label="Page navigation example">
        <ul class="pagination pagination-sm">
          <li class="page-item">
            <a class="page-link" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item"><a class="page-link page-item-number">1</a></li>
          <li class="page-item"><a class="page-link page-item-number">2</a></li>
          <li class="page-item"><a class="page-link page-item-number active">3</a></li>
          <li class="page-item"><a class="page-link page-item-number">4</a></li>
          <li class="page-item"><a class="page-link page-item-number">5</a></li>
          <li class="page-item">
            <a class="page-link" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item"><a class="page-link page-item-number" href="#">1</a></li>
          <li class="page-item"><a class="page-link page-item-number" href="#">2</a></li>
          <li class="page-item"><a class="page-link page-item-number active" href="#">3</a></li>
          <li class="page-item"><a class="page-link page-item-number" href="#">4</a></li>
          <li class="page-item"><a class="page-link page-item-number" href="#">5</a></li>
          <li class="page-item">
            <a class="page-link" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div><br>
    <h3><b>Popovers</b></h3>
    <div class="container">
      <button type="button" class="btn btn-info" popover="I will hide on blur" triggers="mouseenter:mouseleave">
        Hover over me!
      </button>
      <br><br>
      <button type="button" class="btn btn-primary" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
        popoverTitle="Popover on top" placement="top">
        Popover on top
      </button>&nbsp;
      <button type="button" class="btn btn-success" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
        popoverTitle="Popover on right" placement="right">
        Popover on right
      </button>&nbsp;
      <button type="button" class="btn btn-info" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
        popoverTitle="Popover auto" placement="auto">
        Popover auto
      </button>&nbsp;
      <button type="button" class="btn btn-dark" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
        popoverTitle="Popover on left" placement="left">
        Popover on left
      </button>&nbsp;
      <button type="button" class="btn btn-light" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
        popoverTitle="Popover on bottom" placement="bottom">
        Popover on bottom
      </button>
    </div><br><br>
    <h3><b>Progress</b></h3>
    <div class="container">
      <span class="progress-badge">Primary</span>
      <span class="progress-value">25%</span>
      <div class="progress">
        <div class="progress-bar progress-primary" role="progressbar" style="width: 25%" aria-valuenow="25"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <br>
      <span class="progress-badge">Info</span>
      <span class="progress-value">25%</span>
      <div class="progress">
        <div class="progress-bar progress-info" role="progressbar" style="width: 25%" aria-valuenow="25"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <br>
      <span class="progress-badge">Success</span>
      <span class="progress-value">50%</span>
      <div class="progress">
        <div class="progress-bar progress-success" role="progressbar" style="width: 50%" aria-valuenow="50"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <br>
      <span class="progress-badge">Warning</span>
      <span class="progress-value">50%</span>
      <div class="progress">
        <div class="progress-bar progress-warning" role="progressbar" style="width: 50%" aria-valuenow="50"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <br>
      <span class="progress-badge">Danger</span>
      <span class="progress-value">75%</span>
      <div class="progress">
        <div class="progress-bar progress-danger" role="progressbar" style="width: 75%" aria-valuenow="75"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <br>
      <span class="progress-badge">Light</span>
      <span class="progress-value">75%</span>
      <div class="progress">
        <div class="progress-bar progress-light" role="progressbar" style="width: 75%" aria-valuenow="75"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div><br><br>
    <h3><b>Sliders</b></h3>
    <div class="container">
      <nouislider [connect]=[false,true,false] [step]=1 [min]="0" [max]="1000" [(ngModel)]="slider2"></nouislider>
      Range: {{slider2}}
      <br>
      <nouislider [connect]=[true,false] [step]=1 [min]="0" [max]="1000" [(ngModel)]="slider1"></nouislider>
      Value: {{slider1}}
    </div><br><br>
    <h3><b>Spinners</b></h3>
    <div class="container">
      <button class="btn btn-primary" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Loading...
      </button>&nbsp;
      <button class="btn btn-primary" type="button" disabled>
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Loading...
      </button><br><br>

      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
      </div><br><br>

      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-grow text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-grow text-danger" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-grow text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-grow text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>&nbsp;
      <div class="spinner-grow text-dark" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div><br>

    <h3><b>Switch</b></h3>
    <div class="container">
      <ui-switch checked></ui-switch>
      <br>
      <ui-switch checked checkedLabel="on" uncheckedLabel="off"></ui-switch>
    </div><br><br>
    <h3><b>Typography</b></h3>
    <div class="container">
      <h5>Paragraphs</h5>
      <div class="container">
        <p class="dark-color">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>
        <p class="light-color">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>
        <p class="primary-color">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>
        <p class="info-color">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>
        <p class="success-color">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>
        <p class="warning-color">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>
        <p class="danger-color">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>
      </div>
      <h5>Quote</h5>
      <div class="container">
        <p class="quote">&bdquo;Enlightenment insofar war oneself selfish reason decieve ubermensch marvelous
          abstract.
          Evil truth christianity faithful endless zarathustra god evil noble will moral
          ubermensch.&ldquo;<br /><br />
          - Nietzsche Ipsum
        </p>
      </div>
    </div><br><br>
  </div>
</div>
