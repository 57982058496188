import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isCollapsed2 = true;
  slider1 = 500;
  slider2 = [300, 700];
  icons = [{ icon: 'fab fa-bitcoin' }];

  constructor() { }

  ngOnInit(): void {
  }

  dismissAlert(): void {
    const element = document.querySelector('.alert');
    element.classList.remove('alert');
  }

  showIconText(): void {
    const element = document.querySelector('.no-opacity');
    element.classList.remove('no-opacity');
    element.classList.add('opacity');
  }

  hideIconText(): void {
    const element = document.querySelector('.opacity');
    element.classList.add('no-opacity');
    element.classList.remove('opacity');
  }

  playVid(): void {
    const graphVid = document.getElementById('graphVid') as HTMLVideoElement;
    if (graphVid.paused) {
      graphVid.play();
    }
    else {
      graphVid.pause();
    }
  }
}
