<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Modal title</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Modal body text goes here.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-left" (click)="modal.close('Close click')">Save changes</button>
    <button type="button" class="btn btn-dark" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>

<button class="btn btn-primary mb-2 mr-2" (click)="openWindowCustomClass(content)">Launch Modal</button>
