import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';

import { UiSwitchModule } from 'ngx-ui-switch';

import { NouisliderModule } from 'ng2-nouislider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PopoverModule } from 'ngx-bootstrap/popover';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { SampleShowcaseComponent } from './components/sample-showcase/sample-showcase.component';
import { HeaderComponent } from './components/header/header.component';
import { ParallaxDirective } from './directives/parallax.directive';
import { HomeComponent } from './components/home/home.component';
import { ProfileSampleComponent } from './components/profile-sample/profile-sample.component';
import { LoginSampleComponent } from './components/login-sample/login-sample.component';
import { ModalComponent } from './components/modal/modal.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    DatepickerComponent,
    SampleShowcaseComponent,
    HeaderComponent,
    ParallaxDirective,
    HomeComponent,
    ProfileSampleComponent,
    LoginSampleComponent,
    ModalComponent,
    DocumentationComponent,
    ImprintComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UiSwitchModule.forRoot({
      size: 'small'
    }),
    NouisliderModule,
    NgbModule,
    HttpClientModule,
    PopoverModule.forRoot(),
    NgxChartsModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
