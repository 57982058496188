import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appParallax]'
})
export class ParallaxDirective {

  // tslint:disable-next-line:no-input-rename
  @Input('ratio') private parallaxRatio: number;
  initialTop: number;

  constructor(private elRef: ElementRef) {
    this.initialTop = this.elRef.nativeElement.getBoundingClientRect().top;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event): void {
    this.elRef.nativeElement.style.top = (this.initialTop - (window.scrollY * this.parallaxRatio)) + 'px';
  }
}
