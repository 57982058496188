<nav class="navbar navbar-expand-lg bg-transparent navbar-dark fixed-top navbar-transparent"
  (scroll)="onWindowScroll($event)">
  <a class="navbar-brand" routerLink="/home">
    <img src='/assets/images/easedLogoCircleOutline.svg' height="31" alt="">
  </a>
  <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
    data-toggle="collapse" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarTogglerDemo02" [ngbCollapse]="isCollapsed">
    <img class="logo-opacity" src='/assets/images/easedLogoCircleOutline.svg' height="31" alt="">
    <i class="fas fa-times fa-lg" (click)="isCollapsed = !isCollapsed"></i>
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0 nav-margin">
      <li class="nav-item active">
        <a class="nav-link light-link" routerLink="/home">
          <i class="fas fa-home"></i>&nbsp;<b>Home</b><span class="sr-only">(current)</span>
        </a>
      </li>
      <li class="nav-item active">
        <a class="nav-link light-link" routerLink="/getting-started"><i class="fas fa-book"></i>&nbsp;<b>Docs</b></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link light-link" routerLink="/imprint"><i
            class="fas fa-address-card"></i>&nbsp;<b>Legal details</b><span class="sr-only">(current)</span></a>
      </li>
    </ul>
    <ul class="navbar-nav mt-2 mt-lg-0 ml-auto justify-content-end">
      <div class="row">
        <div class="col-4">
          <li class="nav-item active">
            <a class="nav-link light-link" href="https://www.xing.com/profile/Tom_Waldau/cv" target="_blank"><i
                class="fab fa-xing" id="xing-icon"></i><span class="sr-only">(current)</span></a>
          </li>
        </div>
        <div class="col-4">
          <li class="nav-item active">
            <a class="nav-link light-link" href="https://gitlab.com/Steasy" target="_blank"><i class="fab fa-gitlab"
                id="gitlab-icon"></i><span class="sr-only">(current)</span></a>
          </li>
        </div>
        <div class="col-4">
          <li class="nav-item active">
            <a class="nav-link light-link" href="https://www.instagram.com/rl.steasy/?hl=de" target="_blank"><i
                class="fab fa-instagram" id="instagram-icon"></i><span class="sr-only">(current)</span></a>
          </li>
        </div>
      </div>
    </ul>
  </div>
</nav>
