import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SampleShowcaseComponent } from './components/sample-showcase/sample-showcase.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileSampleComponent } from './components/profile-sample/profile-sample.component';
import { LoginSampleComponent } from './components/login-sample/login-sample.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { ImprintComponent } from './components/imprint/imprint.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'profile-sample', component: ProfileSampleComponent },
  { path: 'login-sample', component: LoginSampleComponent },
  { path: 'sample-page', component: SampleShowcaseComponent },
  { path: 'getting-started', component: DocumentationComponent },
  { path: 'imprint', component: ImprintComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
