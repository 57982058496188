<ngb-datepicker #dp [(ngModel)]="model" (navigate)="date = $event.next"></ngb-datepicker>

<hr/>

<p>Tap on the datepicker or click a button</p>
<button class="btn btn-sm btn-outline-primary mr-2" (click)="selectToday()">Select Today</button>
<button class="btn btn-sm btn-outline-primary mr-2" (click)="dp.navigateTo()">To current month</button>

<hr/>

<pre>Model: {{ model | json }}</pre>
