<app-header></app-header>
<div class="container">
  <h1>Documentation</h1>
</div>
<div class="container">
  As this product is still a WIP, there is no finalized documentation yet. Instead, you can temporarily use
  the documentation for <a href="https://getbootstrap.com/docs/4.5/getting-started/introduction/"
    target="_blank">Bootstrap</a>, as the individual
  components are based on
  it. I am currently working on breaking down the stylesheet into bootstrap components and improving intricacies. You can email me if you want
  to use the entire sheet by then. After importing the stylesheet, your web application will be adjusted automatically. - v0.852
  <br><br>
  <div class="container">
    <h4>Additional Plugins</h4>
    <div class="container ol-width align-mid">
      <ol class="gradient-list">
        <li>ng2-nouislider &nbsp; <a href="https://www.npmjs.com/package/ng2-nouislider" target="_blank"><button
              class="btn btn-info">Docs</button></a></li>
        <li>NgbDatepicker &nbsp; <a href="https://ng-bootstrap.github.io/#/components/datepicker/overview"
            target="_blank"><button class="btn btn-info">Docs</button></a></li>
        <li>NgbModal &nbsp; <a href="https://ng-bootstrap.github.io/#/components/modal/examples" target="_blank"><button
              class="btn btn-info">Docs</button></a></li>
        <li>NgxCharts &nbsp; <a href="https://swimlane.gitbook.io/ngx-charts/" target="_blank"><button
              class="btn btn-info">Docs</button></a></li>
        <li>NgxPopover &nbsp; <a href="https://www.npmjs.com/package/ngx-popover" target="_blank"><button
              class="btn btn-info">Docs</button></a></li>
        <li>NgxUISwitch &nbsp; <a href="https://www.npmjs.com/package/ngx-ui-switch" target="_blank"><button
              class="btn btn-info">Docs</button></a></li>
      </ol>
    </div>
  </div>
</div>
