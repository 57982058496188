import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sample-showcase',
  templateUrl: './sample-showcase.component.html',
  styleUrls: ['./sample-showcase.component.scss']
})
export class SampleShowcaseComponent implements OnInit {

  slider1 = 500;
  slider2 = [300, 700];
  isCollapsed = true;
  isCollapsed2 = true;
  isCollapsed3 = true;

  constructor() { }

  ngOnInit(): void {
  }

}
