<div class="page-header">
  <div class="page-header-image" appParallax [ratio]="-0.4"></div>
  <div class="container">
    <div class="align-mid">
      <img id="logoOnBackground" src="/assets/images/easedLogoCircleOutline.svg">
      <div class="input-margin input-group">
        <span class="input-group-addon"><i class="far fa-envelope"></i></span>
        <input id="email" type="text" class="form-control input-padding" name="email" placeholder="Email">
      </div><br>
      <div class="input-margin input-group">
        <span class="input-group-addon"><i class="fas fa-lock"></i></span>
        <input id="password" type="password" class="form-control input-padding" name="password" placeholder="Password">
      </div><br><br>
      <button class="btn btn-primary btn-rounded"><b>LOGIN</b></button>
    </div>
  </div>
</div>
