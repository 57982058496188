import { Component, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  constructor(private modalService: NgbModal) { }

  openWindowCustomClass(content) {
    const modalRef = this.modalService.open(content, {windowClass: 'modal-holder'});
    modalRef.componentInstance.name = 'World';
  }

}
